import React from "react";
import styles from "./AtuationsSection.module.css";
import prisaoFlagrante from "./imgs/Prisão em Flagrante copy2.png";
import habeasCorpus from "./imgs/habeascorpus copy.png";
import tribunal from "./imgs/tribunal copy.png";
import defesaCriminal from "./imgs/defesa-criminal copy.png";
import metodoDefesa from "./imgs/metodo-defesa copy.png";
import execucaoPenal from "./imgs/execução penal copy.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserShield } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { faGavel } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const AtuationsSection = () => {
  return (
    <>
      <div className={styles.container}>
        <h2 className={styles.title}>Áreas de Atuação</h2>

        <div className={styles.mainContainerIcons}>
          <div className={styles.containerIcons}>
            <div className={styles.containerIconsAndText}>
              <div className={styles.iconAndTitle}>
                <FontAwesomeIcon
                  icon={faSearch}
                  color="#c79f47"
                  style={{ fontSize: "40px" }}
                />
                <p className={styles.cardTitle}>Fase Investigativa</p>
              </div>
              <ul  >
                <li className={styles.explanationText}>
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                  <span> B.O - Boletim de Ocorrência </span>
                </li>

                <li className={styles.explanationText}>
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                  <span>Flagrante Delito – Prisão</span>
                </li>

                <li className={styles.explanationText}>
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                  <span>Intimação Policial – Acompanhamento</span>
                </li>

                <li className={styles.explanationText}>
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                  <span>Depoimento em Delegacia - Orientação</span>
                </li>

                <li className={styles.explanationText}>
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                  <span>Queixa-crime – Calunia, Injuria e Difamação</span>
                </li>
              </ul>
            </div>

            <div className={styles.containerIconsAndText}>
              <div className={styles.iconAndTitle}>
              <FontAwesomeIcon
                icon={faAddressCard}
                color="#c79f47"
                style={{ fontSize: "40px" }}
              />
                <p className={styles.cardTitle}>Fase Pré-Processual</p>
              </div>
              <ul  >
                <li className={styles.explanationText}>
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                  <span> Prisão em Flagrante </span>
                </li>

                <li className={styles.explanationText}>
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                  <span>Delegacia – Atendimento 24 horas</span>
                </li>

                <li className={styles.explanationText}>
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                  <span>Audiência de Custódia </span>
                </li>

                <li className={styles.explanationText}>
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                  <span>Análise e Orientação Processual</span>
                </li>

                <li className={styles.explanationText}>
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                  <span>ANPP Acordo de Não Persecução Penal</span>
                </li>
              </ul>
            </div>


            <div className={styles.containerIconsAndText}>
              <div className={styles.iconAndTitle}>
              <FontAwesomeIcon
                icon={faUserShield}
                color="#c79f47"
                style={{ fontSize: "40px" }}
              />
                <p className={styles.cardTitle}>Fase Processual</p>
              </div>
              <ul  >
                <li className={styles.explanationText}>
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                  <span> Transação Penal </span>
                </li>

                <li className={styles.explanationText}>
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                  <span>Suspensão Condicional do Processo</span>
                </li>

                <li className={styles.explanationText}>
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                  <span>Defesa Criminal</span>
                </li>

                <li className={styles.explanationText}>
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                  <span>Audiência de Instrução, Debates e Julgamento</span>
                </li>

                <li className={styles.explanationText}>
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                  <span>RA - Resposta a Acusação</span>
                </li>

                <li className={styles.explanationText}>
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                  <span>HC - Habeas Corpus</span>
                </li>

                <li className={styles.explanationText}>
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                  <span>Liberdade Provisória</span>
                </li>
              </ul>
            </div>


          </div>

          <div className={styles.containerIcons}>


          <div className={styles.containerIconsAndText}>
              <div className={styles.iconAndTitle}>
              <FontAwesomeIcon
                icon={faCopy}
                color="#c79f47"
                style={{ fontSize: "40px" }}
              />
                <p className={styles.cardTitle}>Fase Recursal</p>
              </div>
              <ul  >
                <li className={styles.explanationText}>
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                  <span> TJ - Tribunal de Justiça </span>
                </li>

                <li className={styles.explanationText}>
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                  <span>STJ – Superior Tribunal Justiça</span>
                </li>

                <li className={styles.explanationText}>
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                  <span>STF – Supremo Tribunal Federal</span>
                </li>

               
              </ul>
            </div>

            <div className={styles.containerIconsAndText}>
              <div className={styles.iconAndTitle}>
              <FontAwesomeIcon
                icon={faGavel}
                color="#c79f47"
                style={{ fontSize: "40px" }}
              />
                <p className={styles.cardTitle}>Fase Execução de Pena</p>
              </div>
              <ul  >
                <li className={styles.explanationText}>
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                  <span> Progressão de Regime – Semiaberto e Aberto </span>
                </li>

                <li className={styles.explanationText}>
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                  <span>Multa Penal – Isenção de Multa</span>
                </li>

                <li className={styles.explanationText}>
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                  <span>Parcelamento de Multas</span>
                </li>

                <li className={styles.explanationText}>
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                  <span>Visita Familiar</span>
                </li>

               
              </ul>
            </div>



          

            
          </div>
        </div>
      </div>
    </>
  );
};

export default AtuationsSection;
