import React from "react";
import styles from "./WhoIsSection.module.css";
import Matheus from "./imgs/MatheusLawyer2.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const WhoIsSection = () => {
  return (
    <>
      <div className={styles.container}>
        <img src={Matheus} alt="Dr. Matheus foto" className={styles.matheus} />
        <div className={styles.informationsContainer}>
          <p className={styles.aboveTitle}>Conheça o dr.</p>
          <h2 className={styles.title}>Matheus Miguel</h2>
          <p className={styles.paragaph1}>
            Matheus Miguel dos Santos, advogado inscrito na OAB/SP nº 448.966,
            pós-graduando em Crimes Contra o Patrimônio e Direito Processual
            Penal, com atuação por todo Brasil. Atencioso, dedicado, visando
            sempre resguardar os interesses e liberdade dos seus clientes,
            buscando sempre o aprimoramento para continuar trabalhando.
          </p>
          <p className={styles.paragaph2}>
            Atuante no mundo jurídico há mais de uma década, nas diversas bancas
            da advocacia de médio e grande porte, especialmente voltado ao
            atendimento de pessoas físicas e jurídicas.
          </p>
          <a
            href="https://wa.me/+5511945867493?text=Olá!%20Preciso%20de%20um%20advogado%20especialista%20em%20Direito%20Criminal"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className={styles.speaknow}>
              {" "}
              <FontAwesomeIcon icon={faWhatsapp} size="2x" />
              FALAR COM O ADVOGADO
            </button>
          </a>{" "}
        </div>
      </div>
    </>
  );
};

export default WhoIsSection;
