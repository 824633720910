import React from "react";
import styles from "./Hero.module.css";
import Heroimg from "./imgs/hero section.jpg";
import Logo from "./imgs/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Hero = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.imageOverlay}>
          <div className={styles.itensContainer}>
            <div className={styles.cropImage}>
              <img src={Logo} alt="Matheus Miguel dos Santos Logo" className={styles.logo} />
            </div>
            <h1 className={styles.title}>Advocacia Criminal</h1>
            <p className={styles.paragraphText}>
            Contrate agora um advogado criminal para te ajudar no seu caso. Clique no botão abaixo e fale agora no WhatsApp

            </p>
            <a
              href="https://wa.me/+5511945867493?text=Olá!%20Preciso%20de%20um%20advogado%20especialista%20em%20Direito%20Criminal"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className={styles.speaknow}>
                {" "}
                <FontAwesomeIcon icon={faWhatsapp} size="2x" />
                FALAR COM O ADVOGADO
              </button>
            </a>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
