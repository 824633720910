import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Testimonial.css";
import Avatar from '@mui/material/Avatar';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import Depoimento1 from './imgs/depoimento1.jpeg';
import Depoimento2 from './imgs/depoimento2.jpeg';
import Depoimento3 from './imgs/depoimento3.jpeg';

const PreviousBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowBackIos style={{ color: "gray", fontSize: "45px" }} />
    </div>
  );
};

const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowForwardIos style={{ color: "gray", fontSize: "45px" }} />
    </div>
  );
};

const Testimonial = () => {
  return (
    <div
      className="testimonial"
      style={{ display: "flex", justifyContent: "center", marginTop: 90 }}
    >
      <div style={{ width: "50%", textAlign: "center" }}>
        <h2 className="title" style={{ marginBottom: 20 }}>Depoimentos dos Clientes</h2>
        <Slider prevArrow={<PreviousBtn />} nextArrow={<NextBtn />} dots>
          <Card img={Depoimento1} name="Angela Quirino" text="Contratei o Dr. Matheus, para defender meu filho que foi preso em flagrante. Na audiência meu filho foi absolvido. Tenho somente que elogiar o trabalho do Doutor, totalmente acolhedor, atencioso e eficiente no que faz." />
          <Card img={Depoimento2} name="Felipe Dutra" text="Estava totalmente desesperado, pois estava sendo investigado por diversos crimes. O Dr. Matheus acompanhou o Inquérito Policial do começo ao fim. Tudo foi resolvido, sequer cheguei a ser processado. A experiência foi sensacional, um verdadeiro alívio, profissionalismo de extrema qualidade. " />
          <Card img={Depoimento3} name="Pedro Henrique" text="Gostaria de expressar minha sincera gratidão e parabenizar pela excelente resolução do caso e pela condução da audiência. Seu profissionalismo, dedicação e habilidade foi essencial. Sua expertise não apenas trouxe clareza ao processo, mas também trouxe um resultado positivo que superou minhas expectativas. Mais uma vez, obrigado por seu trabalho excepcional e compromisso com a justiça." />
        </Slider>
      </div>
    </div>
  );
};

const Card = ({ img, name, text }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        color: "black",
      }}
    >
      <Avatar
        imgProps={{ style: { borderRadius: "50%" } }}
        src={img}
        style={{
          width: 120,
          height: 120,
          border: "1px solid lightgray",
          padding: 7,
          marginBottom: 20,
        }}
      />
      <p>{text}</p>
      <p style={{ fontStyle: "italic", marginTop: 25 }}>
        <span style={{ fontWeight: 500, color: "#c79f47" }}>{name}</span>
      </p>
    </div>
  );
};

export default Testimonial;
