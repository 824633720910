import React from "react";
import styles from "./Footer.module.css";
import LogoBlack from "./imgs/logo cinza.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.itensContainer}>
          <div className={styles.cropImage}>
            <img
              src={LogoBlack}
              alt="Matheus Miguel dos Santos Logo"
              className={styles.logo}
            />
          </div>

          <div className={styles.iconItem + " " + styles.instagramIcon}>
            <a
              href="https://www.instagram.com/advogados.mms"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} />
              <span>@advogados.mms</span>
            </a>
          </div>

          <div className={styles.iconItem + " " + styles.whatsappIcon}>
            <a
              href="https://wa.me/+5511945867493?text=Olá!%20Preciso%20de%20um%20advogado%20especialista%20em%20Direito%20Criminal"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faWhatsapp} />
              <span>+55 11 94586-7493</span>
            </a>
          </div>

          <div className={styles.iconItem + " " + styles.emailIcon}>
            <a
              href="mailto:matheusmigueladv@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faEnvelope} />
              <span>matheusmigueladv@gmail.com</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
