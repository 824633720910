import styles from "./App.module.css";
import AtuationsSection from "./AtuationsSection";
import Footer from "./Footer";
import Hero from "./Hero";
import Testimonial from "./Testimonial";
import WhoIsSection from "./WhoIsSection";

function App() {
  return (
    <>
      <div className={styles.container}>
        <Hero />
        <AtuationsSection />
        <WhoIsSection />
        <Testimonial />
        <Footer />
      </div>
    </>
  );
}

export default App;
